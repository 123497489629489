import BaseService from './baseService';


export default class RechercheService extends BaseService {
  getRechercheQuoiSimple(query) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheQuoiSimple', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheQuoi(query) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheQuoi', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheOu(query) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheOu', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheAffineeFormationPaginated(lazyParams) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' },
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/recherche/GetRechercheAffineeFormationPaginated', JSON.stringify(data), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheAffineeFormationCount(lazyParams) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' },
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/recherche/GetRechercheAffineeFormationCount', JSON.stringify(data), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getMinMaxCostFormation(lazyParams) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' },
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/recherche/GetMinMaxCostFormation', JSON.stringify(data), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getFormationFavorites(lazyParams) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' },
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/recherche/GetFormationFavorites', JSON.stringify(data), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getFormationFavoritesEntreprise(lazyParams, entrepriseId) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { entrepriseId: entrepriseId }
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/recherche/GetFormationFavoritesEntreprise', JSON.stringify(data), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheAffineeCandidats(lazyParams) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' },
    };

    return this.axiosApiInstance.post(window.API_URL + '/api/recherche/RechercheAffineeCandidats', JSON.stringify(data), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheCandidats(lazyParams) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' },
    };

    return this.axiosApiInstance.post(window.API_URL + '/api/recherche/RechercheCandidats', JSON.stringify(data), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheCandidatsCount(lazyParams) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' },
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/recherche/GetRechercheCandidatsCount', JSON.stringify(data), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getMatchingAlias(filters) {

    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: super.getQueryParamsFromArray(filters, "RechercheLabelValueViewModel")
    };

    const queryParams = super.getQueryParams({ filters: filters })
    return this.axiosApiInstance.get(window.API_URL + `/api/recherche/GetMatchingAlias?` + queryParams)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));

  }
  getRechercheOrganisme(query) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheOrganisme', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheDiplome(query, lazyParams) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query }
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/recherche/GetRechercheDiplome', JSON.stringify(data), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheFormation(query, lazyParams) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query }
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/recherche/GetRechercheFormation', JSON.stringify(data), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheLocalisation(query, isUserSpecific=false) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query, isUserSpecific: isUserSpecific }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheLocalisation', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheLocalisationOffreEmploiEntreprise(entrepriseId) {
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheLocalisationOffreEmploiEntreprise' + (entrepriseId ? ("/" + entrepriseId): ""))
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheLocalisationCandidatsOffreEmploi(offreEmploiId) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { offreEmploiId: offreEmploiId }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheLocalisationCandidatsOffreEmploi', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheMobilite(query) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheMobilite', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheLangue(query) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheLangue', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheAppellation(query) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheAppellation', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheCompetence(query, categorie) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: {
        chaineRecherchee: query,
        categorie: categorie
      }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheCompetence', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheCompetenceSavoirSavoirFaire(query) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: {
        chaineRecherchee: query
      }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheCompetenceSavoirSavoirFaire', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheMobilite(query) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheMobilite', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }


  setRechercheAffineeCode(query) {
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/recherche/setRechercheAffineeCode', JSON.stringify(query), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheAffineeByCode(query) {
    const options = {
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', },

      params: { code: query }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/getRechercheAffineeByCode', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheDipDomaine(query) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query || null }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheDipDomaine', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheDipMetier(query, lazyParams) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query || null }
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/recherche/GetRechercheDipMetier', JSON.stringify(data), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheDipMetierSingleDipDomaine(query, dipDomaineId) {
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: {
        chaineRecherchee: query || null,
        dipDomaineId: dipDomaineId || null,
      }
    };
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheDipMetierSingleDipDomaine', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheAppellationFunnel(query, lazyParams) {
    const data = super.getAPIPagedQuery(lazyParams)
    const options = {
      headers: { 'Content-Type': 'application/json' },
      params: { chaineRecherchee: query || null }
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/recherche/GetRechercheAppellationFunnel', JSON.stringify(data), options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheProjetOfEntreprise(entrepriseId) {
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheProjetOfEntreprise' + (entrepriseId ? ("/" + entrepriseId) : ""))
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getRechercheLocalisationOfFormationFavorites() {
    return this.axiosApiInstance.get(window.API_URL + '/api/recherche/GetRechercheLocalisationOfFormationFavorites')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }
}
