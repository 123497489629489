import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router';
import { useTranslation } from "react-i18next"
import { Toast } from "primereact/toast";
import { useAuthState } from "../../context/context";
import RechercheService from '../../services/rechercheService';
import "../home/home.css";
import './rechercheAffinee.css'
import { MenuSearchFilter } from './menu/menuSearchFilter';
import { NewNbResultsAndOrder } from './bar/newNbResultsAndOrderBar';
import RechercheContext from './RechercheContext';
import { enumBreakpoint } from '../../enums/enumBreakpoint';
import { MenuPopUpSearchFilter } from './menu/menuPopUpSearchFilter';
import { RechercheAffineeResultats } from './rechercheAffineeResultats';
import { enumTypeRechercheAffinee } from '../../enums/enumTypeRechercheAffinee';
import { enumCategorieRecherche } from '../../enums/enumCategorieRecherche';
import { ButtonGoBack } from '../../components/buttonGoBack/buttonGoBack';

export const RechercheAffinee = ({ backgroundTheme, className }) => {

    const rechercheService = new RechercheService();
    const { t } = useTranslation();
    const toast = useRef(null);
    const currentUser = useAuthState();
    const location = useLocation();
    const history = useHistory();
    const [formations, setFormations] = useState([]);
    const [candidats, setCandidats] = useState([])
    const [totalFormationsRecords, setTotalFormationsRecords] = useState(0);
    const [totalCandidatsRecords, setTotalCandidatsRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const [loadingFilter, setLoadingFilter] = useState(false);
    const [loadingTotalFormationsRecords, setLoadingTotalFormationsRecords] = useState(false)
    const [filtersActiveIndex, setFiltersActiveIndex] = useState([]);
    const [matchedAlias, setMatchedAlias] = useState(false);
    const [typeRecherche, setTypeRecherche] = useState(enumTypeRechercheAffinee.FORMATION)
    const [tokenResetFilters, setTokenResetFilters] = useState(null);
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId

    const _lazyParamsDefault = {
        first: 0,
        rows: 12,
        page: 1,
        sortField: 'Pertinence',
        sortOrder: 1,
        filters: [{ categorie: enumCategorieRecherche.FormationActive, value: true }, { categorie: enumCategorieRecherche.FormationVisible, value: true }]
    };

    const [lazyParams, setLazyParams] = useState(_lazyParamsDefault);



    const tryParseJsonFilters = async (_lazyParams) => {

        var query = new URLSearchParams(history.location.search);
        var rechercheCode = null;

        try {
            rechercheCode = query.get('RechercheCode');
            if (rechercheCode) {
                return rechercheService.getRechercheAffineeByCode(rechercheCode)
            }
            else {
                return null;
            }
        }
        catch
        {
            return null;
        }
    }


    const showError = () => {
        toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('general.loading_failed'), life: 3000 });
    }

    const loadLazyData = (_lazyParams) => {
        onRechercheChanged(_lazyParams);
    }

    const onRechercheChanged = (_lazyParams, _typeRecherche) => {
        let _newLazyParams = _lazyParams;
        setLazyParams(_newLazyParams)
        setLoading(true);
        setLoadingTotalFormationsRecords(true);
        if (_typeRecherche == enumTypeRechercheAffinee.FORMATION || (_typeRecherche == null && contextRecherche.typeRecherche == enumTypeRechercheAffinee.FORMATION)) {
            setTotalCandidatsRecords(0);

            var filters = _lazyParams.filters
            rechercheService.setRechercheAffineeCode(JSON.stringify(_lazyParams.filters)).then((data) => {
                window.history.replaceState(filters, '', "/rechercheAffinee?RechercheCode=" + data)
            }
            )
            rechercheService
                .getRechercheAffineeFormationPaginated(_newLazyParams)
                .then((data) => {
                    setFormations(data.items);
                })
                .catch(err => showError())
                .finally(() => setLoading(false))

            rechercheService.
                getRechercheAffineeFormationCount(_newLazyParams)
                .then((totalCount) => {
                    setTotalFormationsRecords(totalCount);
                })
                .catch(err => showError())
                .finally(() => setLoadingTotalFormationsRecords(false))
        }
        else if (_typeRecherche == enumTypeRechercheAffinee.CANDIDAT || (_typeRecherche == null && contextRecherche.typeRecherche == enumTypeRechercheAffinee.CANDIDAT)) {
            setTotalFormationsRecords(0);
            rechercheService.getRechercheAffineeCandidats(_newLazyParams)
                .then((data) => {
                    setTotalCandidatsRecords(data.totalCount);
                    setCandidats(data.items);
                })
                .catch(err => {
                    showError()
                    console.log(err)
                })
                .finally(() => {
                    setLoading(false);
                    setLoadingTotalFormationsRecords(false);
                })
            // setTotalCandidatsRecords(0);
            // setCandidats(null);
        }
        else {
            showError()
            setLoading(false)
        }
    }

    const handleClicEffacerFiltres = (e) => {
        e.preventDefault()
        try {
            setTokenResetFilters(new Date())
            contextRecherche.setLazyParams(_lazyParamsDefault)
            contextRecherche.rechercheApi(_lazyParamsDefault)
        } catch (error) {
            console.log(error);
        }
    }

    const onClickChangePage = (lazyParams) => {
        setLoadingButton(true);

        switch (contextRecherche.typeRecherche) {
            case enumTypeRechercheAffinee.FORMATION:
                setTotalCandidatsRecords(0);
                rechercheService
                    .getRechercheAffineeFormationPaginated(lazyParams)
                    .then((data) => {
                        setFormations(data.items);
                    })
                    .catch(err => showError())
                    .finally(() => setLoadingButton(false))
                break;
            case enumTypeRechercheAffinee.CANDIDAT:
                setTotalFormationsRecords(0);
                rechercheService.getRechercheAffineeCandidats(lazyParams)
                    .then((data) => {
                        setTotalCandidatsRecords(data.totalCount);
                        setCandidats(data.items);
                    })
                    .catch(err => showError())
                    .finally(() => setLoadingButton(false))
                break;
            default:
                showError()
                setLoadingButton(false)
                break;
        }
    }

    const contextRecherche = {
        lazyParams,
        setLazyParams,
        rechercheApi: onRechercheChanged,
        totalFormationsRecords,
        totalCandidatsRecords,
        categorieRecherche: enumCategorieRecherche,
        typeRecherche,
        setTypeRecherche,
        tokenResetFilters
    }

    const matchAlias = (filters, filtersNonAlias) => {
        if (!matchedAlias) {
            setLoadingFilter(true);

            rechercheService.getMatchingAlias(filters).then(_filters => {

                let _lazyParams = { ...lazyParams };

                _filters.forEach(element => {
                    if (element.categorie === 0)
                        element.exige = false;
                });
                //_lazyParams.filters = _lazyParams.filters.concat(filtersNonAlias);
                _lazyParams.filters = [...new Set([..._lazyParams.filters, ...filtersNonAlias, ..._filters])];
                // _lazyParams.filters = _lazyParams.filters.concat(_filters);
                // _lazyParams.filters = [...new Set([..._lazyParams.filters ,..._filters])];
                setLazyParams(_lazyParams);
                loadLazyData(_lazyParams);
                setMatchedAlias(true);
            })
                .catch(err => showError())
                .finally(() => setLoadingFilter(false));
        }
    }

    const filterGetNonAliasOnly = (filters) => {
        return filters.filter(function (item) {
            return !item.alias;
        });
    }

    const filterGetAliasOnly = (filters) => {
        return filters.filter(function (item) {
            return (item.categorie == enumCategorieRecherche.Competence || item.categorie == enumCategorieRecherche.Appellation) && item.alias;
        });
    }

    useEffect(() => {

        (async function ueWaiter() {
            let rawfilters = await tryParseJsonFilters(lazyParams);
            let filters = rawfilters == "" ? "" : JSON.parse(rawfilters);

            if (filters) {
                let _lazyParams = { ...lazyParams };
                _lazyParams.filters = filters
                setLazyParams(_lazyParams);
                loadLazyData(_lazyParams);
            }
            else if (history.location.state?.filters) {

                let _filtersNonAlias = filterGetNonAliasOnly(history.location?.state?.filters)
                if (_filtersNonAlias.map((f) => { return f.categorie; }).indexOf(enumCategorieRecherche.FormationActive) == -1) {
                    _filtersNonAlias.push({ categorie: enumCategorieRecherche.FormationActive, value: true }) //formation active par defaut
                    _filtersNonAlias.push({ categorie: enumCategorieRecherche.FormationVisible, value: true }) //formation visible par defaut

                }
                let _filtersAlias = filterGetAliasOnly(history.location.state.filters)
                if (_filtersAlias.length) {
                    matchAlias(_filtersAlias, _filtersNonAlias);
                    let _lazyParams = { ...lazyParams };
                    _lazyParams.filters = _filtersNonAlias;
                    setLazyParams(_lazyParams);
                }
                else {
                    let _lazyParams = { ...lazyParams };
                    _lazyParams.filters = _filtersNonAlias;
                    setLazyParams(_lazyParams);
                    loadLazyData(_lazyParams);
                }
            }
            else {
                loadLazyData(lazyParams);
            }
        })()


    }, [location])

    return (
        <RechercheContext.Provider value={contextRecherche}>
            <div className={className}>
                <Toast ref={toast} />
                <div className="refined-search-main-container">
                    <div className='refined-search-title-container'>
                        <div className='refined-btn-goback' id='top'>
                            <ButtonGoBack backgroundColor={backgroundTheme} />
                        </div>
                        <NewNbResultsAndOrder
                            className="dip-w-100"
                            handleClicEffacerFiltres={handleClicEffacerFiltres}
                            loadingTotalFormationsRecords={loadingTotalFormationsRecords}
                        />
                    </div>
                    <div className="refined-search-lower-container">
                        <MenuSearchFilter
                            className={`refined-search-filters-container`}
                            activeIndex={filtersActiveIndex}
                            setActiveIndex={setFiltersActiveIndex}
                            handleClicEffacerFiltres={handleClicEffacerFiltres}
                        />
                        <div className={`refined-search-right-container`}>
                            <RechercheAffineeResultats
                                className={`refined-search-results-container-filters`}
                                backgroundColor={backgroundTheme}
                                formations={formations}
                                candidats={candidats}
                                loading={loading}
                                loadingButton={loadingButton}
                                mainFilterActiveIndex={0}
                                lazyParams={lazyParams}
                                setLazyParams={setLazyParams}
                                totalFormationsRecords={typeRecherche == enumTypeRechercheAffinee.FORMATION ? totalFormationsRecords : totalCandidatsRecords}
                                onClickChangePage={onClickChangePage}
                                toast={toast}
                                currentUser={currentUser}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='p-jc-center p-mt-2 refined-search-float-button'>
                <MenuPopUpSearchFilter activeIndex={filtersActiveIndex} setActiveIndex={setFiltersActiveIndex} handleClicEffacerFiltres={handleClicEffacerFiltres} />
            </div>
        </RechercheContext.Provider>
    )
}