import axios from 'axios';
import BaseService from './baseService';

const CancelToken = axios.CancelToken;

export default class UserService extends BaseService {
  getAdministrateurs(currentUser) {
    const options = {
      headers: { Authorization: 'Bearer ' + currentUser.token }
    };
    return axios.get(window.API_URL + '/api/user/GetAdministrateurs', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  createOrUpdateAdministrateur(administrateur) {
    const jsonAdministrateur = JSON.stringify(administrateur, null, 2);
    return this.axiosApiInstance.post(window.API_URL + '/api/user/administrateur', jsonAdministrateur)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }
  getAdministrateursComposanteEtEcole(ecoleId, lazyParams) {
    const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))

    return this.axiosApiInstance.get(window.API_URL + `/api/user/GetAdministrateursEcole/${ecoleId}?` + queryParams)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }
  getAdministrateur(userId) {

    return this.axiosApiInstance.get(window.API_URL + `/api/user/GetAdministrateurEcole/${userId}?`)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  createAdministrateurComposante(administrateur) {
    const jsonAdministrateur = JSON.stringify(administrateur, null, 2);

    return this.axiosApiInstance.post(window.API_URL + '/api/user/CreateAdministrateurComposante', jsonAdministrateur)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }
  createAdministrateurEcole(administrateur) {
    const jsonAdministrateur = JSON.stringify(administrateur, null, 2);

    return this.axiosApiInstance.post(window.API_URL + '/api/user/CreateAdministrateurEcole', jsonAdministrateur)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  updateAdministrateurComposante(administrateur) {
    const jsonAdministrateur = JSON.stringify(administrateur, null, 2);

    return this.axiosApiInstance.post(window.API_URL + '/api/user/UpdateAdministrateurComposante', jsonAdministrateur)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));

  }
  updateAdministrateurEcole(administrateur) {
    const jsonAdministrateur = JSON.stringify(administrateur, null, 2);

    return this.axiosApiInstance.post(window.API_URL + '/api/user/UpdateAdministrateurEcole', jsonAdministrateur)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getEditeurs(currentUser) {
    const options = {
      headers: { Authorization: 'Bearer ' + currentUser.token }
    };
    return axios.get(window.API_URL + '/api/user/GetEditeurs', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getEditeursLight() {

    return this.axiosApiInstance.get(window.API_URL + '/api/user/GetEditeursLight')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getEtudiants(currentUser, lazyParams) {
    const options = {
      headers: { Authorization: 'Bearer ' + currentUser.token },
    };
    const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))

    return axios.get(window.API_URL + '/api/user/GetEtudiants?' + queryParams, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getEtudiantsDocumentAValider(currentUser) {
    const options = {
      headers: { Authorization: 'Bearer ' + currentUser.token }
    };
    return axios.get(window.API_URL + '/api/user/EtudiantsDocumentAValider', options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  createOrUpdateEditeur(currentUser, editeur) {
    const jsonEditeur = JSON.stringify(editeur, null, 2);
    const options = {
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + currentUser.token }
    };
    return axios.post(window.API_URL + '/api/user/editeur', jsonEditeur, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  createEtudiant(etudiant, urlRedirect) {
    const jsonEtudiant = JSON.stringify(etudiant, null, 2);
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };

    if (urlRedirect != null && urlRedirect != "") {
      return axios.post(window.API_URL + '/api/user/etudiant?urlRedirect=' + urlRedirect, jsonEtudiant, options)
        .then(res => res.data)
        .catch(err => super.handleHttpError(err));
    }
    else {
      return axios.post(window.API_URL + '/api/user/etudiant', jsonEtudiant, options)
        .then(res => res.data)
        .catch(err => super.handleHttpError(err));
    }
  }

  createCompteEtudiantInvite(currentUser,etudiant) {
    const jsonEtudiant = JSON.stringify(etudiant, null, 2);
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + currentUser.token
      }
    }
    const formData = new FormData()
    formData.append('etudiant', jsonEtudiant);
    if (etudiant.photo !== undefined)
      formData.append('photo', new Blob([etudiant.photo], { type: etudiant.photo.type }), etudiant.photo.name);
    if (etudiant.cvPdf !== undefined)
      formData.append('cvPdf', new Blob([etudiant.cvPdf], { type: etudiant.cvPdf.type }), etudiant.cvPdf.name);
    if (etudiant.portfolio !== undefined)
      formData.append('portfolio', new Blob([etudiant.portfolio], { type: etudiant.portfolio.type }), etudiant.portfolio.name);

    return axios.post(window.API_URL + '/api/user/CreateCompteEtudiantInvite', formData, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  createEntreprise(entreprise) {
    const jsonEntreprise = JSON.stringify(entreprise, null, 2);
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };
    return axios.post(window.API_URL + '/api/user/entreprise', jsonEntreprise, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  updateEntreprise(currentUser, entreprise) {
    const jsonEntreprise = JSON.stringify(entreprise, null, 2);
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + currentUser.token
      }
    };

    const formData = new FormData()
    formData.append('Entreprise', jsonEntreprise);
    if (entreprise.logoFile !== undefined)
      formData.append('LogoFile', new Blob([entreprise.logoFile], { type: entreprise.logoFile.type }), entreprise.logoFile.name);
    if (entreprise.logoEntrepriseFile !== undefined)
      formData.append('LogoEntrepriseFile', new Blob([entreprise.logoEntrepriseFile], { type: entreprise.logoEntrepriseFile.type }), entreprise.logoEntrepriseFile.name);

    return axios.post(window.API_URL + '/api/entreprise/UpdateEntrepriseProfil', formData, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getEntreprise(userId) {
    return this.axiosApiInstance.get(window.API_URL + '/api/user/getEntrepriseProfil=' + userId)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  existEmail(email) {
    return axios.get(window.API_URL + '/api/user/existemail=' + email)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  activateaccount(email, token) {
    return axios.get(window.API_URL + '/api/user/activateaccount/?email=' + email + '&token=' + token)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  edit(user) {
    const jsonUser = JSON.stringify(user, null, 2);
    return this.axiosApiInstance.put(window.API_URL + '/api/user/' + user.id + '/edit', jsonUser)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  delete(user) {
    return this.axiosApiInstance.delete(window.API_URL + '/api/user/' + user.id)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  hardDelete(user) {
    return this.axiosApiInstance.delete(window.API_URL + '/api/user/hardDeleteEtudiant/' + user.id)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getUserWithEcole() {
    return this.axiosApiInstance.get(window.API_URL + '/api/user/GetUserWithEcole')
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getCandidat(url) {
    return this.axiosApiInstance.get(window.API_URL + '/api/user/Candidat/' + url)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getCandidatRecutements(url) {

    return this.axiosApiInstance.get(window.API_URL + `/api/user/CandidatRecrutements/${url}`)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  UpdateCandidatRecutements(url, etudiantRecrutement) {
    const jsonEtudiantRecrutement = JSON.stringify(etudiantRecrutement);
    return this.axiosApiInstance.post(window.API_URL + `/api/user/UpdateCandidatRecrutements/${url}`, jsonEtudiantRecrutement)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  archiverEtudiant(etudiant) {
    const jsonEtudiant = JSON.stringify(etudiant, null, 2);
    return this.axiosApiInstance.post(window.API_URL + '/api/user/archiverEtudiant', jsonEtudiant)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  postFormulaireContact(form) {
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };
    const model = JSON.stringify(form, null, 2);
    return axios.post(window.API_URL + '/api/user/PostFormulaireContact', model, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getFormulaireContactPreFilled() {
    return this.axiosApiInstance.get(window.API_URL + `/api/user/GetFormulaireContactPreFilled`)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  postFormulaireContactEntreprise(form) {
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };
    const model = JSON.stringify(form, null, 2);
    return this.axiosApiInstance.post(window.API_URL + '/api/user/PostFormulaireContactEntreprise', model, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  postFormulaireContactEtudiant(form, statut) {
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };
    const model = JSON.stringify(form, null, 2);
    return this.axiosApiInstance.post(window.API_URL + '/api/user/PostFormulaireContactEtudiant/' + statut, model, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getListEtudiantByFormationId(lazyParams, formationId, entrepriseId) {
    var source = CancelToken.source();
    const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))
    return this.axiosApiInstance.get(window.API_URL + `/api/user/GetListEtudiantByFormationId?` + queryParams + "&formationId=" + formationId + (entrepriseId ? ("&entrepriseId=" + entrepriseId) : ""), { cancelToken: source.token })
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

  getInfoBeforeDeleteStudent(etudiantId) {
    return this.axiosApiInstance.get(window.API_URL + `/api/user/GetInfoBeforeDeleteStudent/${etudiantId}`)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }
  
  createCollaborateurInvite(collaborateur) {
    const jsonCollaborateur = JSON.stringify(collaborateur, null, 2);
    const options = {
      headers: { 'Content-Type': 'application/json' }
    };
    return this.axiosApiInstance.post(window.API_URL + '/api/user/CreateCollaborateurInvite', jsonCollaborateur, options)
      .then(res => res.data)
      .catch(err => super.handleHttpError(err));
  }

}
