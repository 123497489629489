export default class Helper {

    static downloadBlobFile(blob, filename) {
        const binaryData = [blob];
        let downloadLink = document.createElement('a');
        let _blob = new Blob(binaryData, { type: 'blob' })
        downloadLink.href = window.URL.createObjectURL(_blob);
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        window.URL.revokeObjectURL(_blob);
    }

    static openWindowBlobFile(blob, filename) {
        // on créer un blob avec le fichier pdf et on l'ouvre dans un nouvel onglet du navigateur
        var _blob = new Blob([blob], { type: 'application/pdf' });
        var _url = window.URL.createObjectURL(_blob);
        var _tab = window.open(_url, '_blank');
        _tab.document.title = filename;
        _tab.focus();
    }

    static openPdfInNewTab(blob, filename) {
        // Créer un Blob avec le type "application/pdf"
        const pdfBlob = new Blob([blob], { type: 'application/pdf' });
      
        // Créer une URL Blob à partir du Blob PDF
        const blobUrl = URL.createObjectURL(pdfBlob);
      
        // Créer un lien <a> pour ouvrir le PDF dans un nouvel onglet
        const link = document.createElement('a');
        link.href = blobUrl;
        link.target = '_blank';
        link.rel = 'noopener noreferrer'; // Pour des raisons de sécurité
        link.style.display = 'none'; // Masquer le lien
        
        // Ajouter le lien à la page
        document.body.appendChild(link);
      
        // Simuler un clic sur le lien pour ouvrir le PDF dans un nouvel onglet
        link.click();
      
        // Libérer l'URL Blob lorsque vous avez terminé
        URL.revokeObjectURL(blobUrl);
      
        // Retirer le lien de la page
        document.body.removeChild(link);
    } 

    static formatNumberMoney = (number) => {
        return Math.ceil(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    static openWindowPictureBlobFile(blob, filename) {
        // on créer un blob avec l'image png,jpeg,ect.. et on l'ouvre dans un nouvel onglet du navigateur
        var _blob = new Blob([blob], { type: 'image/png' });
        var _url = window.URL.createObjectURL(_blob);
        var _tab = window.open(_url, '_blank');
        _tab.document.title = filename;
        _tab.focus();
    }

    static isEmptyOrSpaces = (str) => {
        return str === null || str.match(/^\s*$/) !== null;
    }

    static findIndexById = (tableau = [], id) => {
        let index = -1;
        for (let i = 0; i < tableau.length; i++) {
            if (tableau[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    };

    static findIndexByValueAndCategorie = (tableau = [], value, categorie) => {
        let index = -1;
        for (let i = 0; i < tableau.length; i++) {
            if (tableau[i].value === value && tableau[i].categorie === categorie) {
                index = i;
                break;
            }
        }
        return index;
    };

    static findIndexByCategorie = (tableau = [], categorie) => {
        let index = -1;
        for (let i = 0; i < tableau.length; i++) {
            if (tableau[i].categorie === categorie) {
                index = i;
                break;
            }
        }
        return index;
    };

    static findFiltersByCategorie = (tableau = [], categorie) => {
        let index = [];
        for (let i = 0; i < tableau.length; i++) {
            if (tableau[i].categorie === categorie) {
                index.push(tableau[i]);
            }
        }
        return index;
    };

    static containsValueAndCategorie = (tableau = [], value, categorie) => {
        for (let i = 0; i < tableau.length; i++) {
            if (tableau[i].value === value && tableau[i].categorie === categorie) {
                return true;
            }
        }
        return false;
    };

    static containsDuplicates = (tab) => {
        var valueArr = tab.map(function (item) { return item.id });
        var isDuplicate = valueArr.some(function (item, idx) {
            return valueArr.indexOf(item) != idx
        });
        return isDuplicate;
    }

    static dateTimeToLocaleDateTime = (dateTime) => {
        let splitString = dateTime.replace(/[A-Z]/, " ").split(' ');
        let _date = new Date(splitString[0].replace(/-/g, "/"))
        let options = { day: '2-digit', month: '2-digit', year: 'numeric' }
        return _date.toLocaleDateString('fr-FR', options);
    }

    static sortLibellesByAlpha(data) {
        return data.sort();
    }


    static clearString(str) {
        if (str && str != "") {
            str = str.trim();
            str = str.replace(/\p{Diacritic}/gu, "");
            str = str.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '')
            str = str.replace(/\s+/g, ' ')
            str = str.replace(/\W+/g, " ")
            str = str.replace(/_+/g, " ")
            str = str.trim();
            str = str.replace(/ +/g, "_")
        }
        return str
    }

    static removeAtIndex = (array, index) => {
        return [...array.slice(0, index), ...array.slice(index + 1)];
    };

    static insertAtIndex = (array, index, item) => {
        return [...array.slice(0, index), item, ...array.slice(index)];
    };

    static getCurrentYear = () => {
        return new Date().getFullYear();
    }

    static isInPeriodeEssai = (creationDate, nbSemaineEssai) => {
        let date = new Date(creationDate);
        let dateNow = new Date();
        let nbJours = (dateNow - date) / (1000 * 3600 * 24);
        return nbJours <= nbSemaineEssai * 7;
    }

}