
import { Editor } from '@tinymce/tinymce-react';
//import { Editor } from 'primereact/editor';
import { useEffect, useRef, useState } from "react";
import { render } from "@testing-library/react";
import WysiwygService from '../../services/wysiwygService';
import { Trans, useTranslation } from 'react-i18next';
import { enumConfWysiwyg } from "../../enums/enumConfWysiwyg";
import { enumConfWysiwygPlugin } from "../../enums/enumConfWysiwygPlugin";
import { Button } from 'primereact/button';
import parse from 'html-react-parser';

export const Wysiwyg = (props) => {

  const { t } = useTranslation();
  const wysiwygService = new WysiwygService();
  const switch_plugin = (Conf) => {
    switch (Conf) {
      case enumConfWysiwyg.ZERO:
        return (enumConfWysiwygPlugin.ZERO);
      case enumConfWysiwyg.SIMPLE:
        return (enumConfWysiwygPlugin.SIMPLE);
      case enumConfWysiwyg.BASIC:
        return (enumConfWysiwygPlugin.BASIC);
      case enumConfWysiwyg.STANDARD:
        return (enumConfWysiwygPlugin.STANDARD);
      case enumConfWysiwyg.STANDARD_LINK_IMG:
        return (enumConfWysiwygPlugin.STANDARD_LINK_IMG);
      case enumConfWysiwyg.STANDARD_UPLOAD_IMG:
        return (enumConfWysiwygPlugin.STANDARD_UPLOAD_IMG);
      case enumConfWysiwyg.ULTRA:
        return (enumConfWysiwygPlugin.ULTRA);
      default:
        return (enumConfWysiwygPlugin.SIMPLE);
    }
  }

  const [configurationWysiwyg, setConfigurationWysiwyg] = useState(props.Conf ? props.Conf : enumConfWysiwyg.SIMPLE);
  const [pluginsWysiwyg, setPluginsWysiwyg] = useState(switch_plugin(props.Conf));
  const [hasToolbarWysiwyg, setHasToolbarWysiwyg] = useState(false);
  const [showEditor, setShowEditor] = useState(false);

  const editorRef = useRef(null);
  const [initialPictures, setInitialPictures] = useState([]);
  const [addedPictures, setAddedPictures] = useState([]);
  const [latsAddedPicture, setLastAddedPicture] = useState("");

  const localContent = useRef("");

  const upload_image = (blobInfo, success, failure) => {
    let data = new FormData();
    data.append('file', blobInfo.blob(), blobInfo.filename());
    var image_size = blobInfo.blob().size / 1000;  // image size in kbytes
    var max_size = 3000                // max size in kbytes
    if (image_size > max_size) {
      failure(t('errors.wysiwyg_uploading_image_tobig', { size: max_size / 1000 }));
    } else {

      wysiwygService.uploadImageHandler(data, props.entityType, props.entityId)
        .then((_data) => {
          var url = _data;
          var pic = addedPictures;
          pic = pic.push(_data);
          setAddedPictures(pic);
          setLastAddedPicture(_data)
          success(_data);
          processData();
        })
        .catch((error) => {
          failure(t('errors.wysiwyg_uploading_image_error'))
        })
        .finally(
      )
    }
  }

  //Not used 
  // const onUnload = () => {

  //   //etat initial du wysiwyg
  //   var a = initialPictures;
  //   //lien ajouté au wysiwyg
  //   var b = addedPictures;

  //   const p1 = a.concat(b);

  //   //etat final du wysiwyg
  //   var c = exportUrlPirctureFromHtml(localContent);

  //   //Images a supprimé
  //   var p2 = p1.filter(x => !c.includes(x))

  //   wysiwygService.removeImageHandler(p2)
  //     .then((_data) => {
  //     })
  //     .catch((error) => {
  //     })
  //     .finally(
  //     )
  // }

  const processData = () => {
    //modifier le dernier lien ajouté  en ajoutant une balise
    if (latsAddedPicture) {
      var reg = new RegExp('(<img+[^\/>]*src=\"' + latsAddedPicture + '\")([^<]*?\/>)', "gi");
      var row = editorRef.current.getContent().match(reg)
      if (row) {
        var lastIndex = row[0].lastIndexOf(" ");
        var id = row[0].replace(/.*src="([^"]*)".*/, '$1').split('/').pop();
        var output = row[0].substring(0, lastIndex) + " id=\"dip-uploaded-" + id + "\"" + row[0].substring(lastIndex);
        var new_content = editorRef.current.getContent().replaceAll(row[0], output);
        editorRef.current.setContent(new_content);

      }
      setLastAddedPicture("");
    }
  }


  //Pour plus tard
  // const exportUrlPirctureFromHtml = (html) => {
  //   var listUrl = [];

  //   //identifier les balises img avec l'attribut 'dip-uploaded'
  //   var htmlReg = new RegExp('(<img+[^\/>]*dip-uploaded)([^<]*?\/>)', "gi");
  //   var htmlRow = html.match(htmlReg)

  //   if (htmlRow != null) {
  //     htmlRow.forEach(function (item) {
  //       var linkReg = new RegExp('\<img.+src\=(?:\"|\')(.+?)(?:\"|\')(?:.+?)\>', "gi");
  //       var linkRow = item.match(linkReg)
  //       if (linkRow != null) {
  //         listUrl.push(linkRow[0].replace(/.*src="([^"]*)".*/, '$1'))
  //       }
  //     });
  //   }
  //   return listUrl;
  // }

  const editorInitialisation = (evt, editor) => {
    editorRef.current = editor;

    //Pour plus tard
    // var pictures = exportUrlPirctureFromHtml(editorRef.current.getContent())
    // pictures.forEach(function (pict) {
    //   var pic = initialPictures;
    //       pic = pic.push(pict); 
    //    setInitialPictures(pic);
    // });

  }

  return (
    <>
      {showEditor ?
        <>
          <Editor
            apiKey="dkif8wq11yhokf8z8g2q2tbowsnnrm80ddigo0yzwnq8hqwe"
            onInit={editorInitialisation}
            onEditorChange={props.onChange}
            onFocusOut={processData}
            value={props.value}
            disabled={props.disabled}
            //id={props.id}
            init={{
              height: props.defaultHeight || 300,
              language: 'fr_FR',
              images_upload_handler: upload_image,
              menubar: hasToolbarWysiwyg,
              plugins: [pluginsWysiwyg],
              toolbar: [configurationWysiwyg],
              // quickbars_image_toolbar: false,
              // quickbars_insert_toolbar:true,

              color_map: [
                '#ed6a5a', 'Terra Cotta',
                '#04486c', 'Indigo Blue',
                '808080', 'Gray'],
              custom_colors: false,
              images_file_types: 'jpeg,jpg,png,bmp,webp',
              block_unsupported_drop: true,
              content_style: 'body { font-family:Work Sans, sans-serif; font-size:14px }'
            }}
          />
        </>
        :
        <>
          {props.value ? <div>{parse(props.value)}</div> : <div></div>}
          <div>
            <Button label={t("wysiwyg.open")} style={{width:'auto'}} className='dip-btn-iris-bg' onClick={() => setShowEditor(true)}></Button>
          </div>
        </>
      }
      {/* <Editor value={props.value} onTextChange={(e) => props.onChange(e.htmlValue)} style={{ height: '320px' }} /> */}
    </>
  )
}
  ;