import BaseService from './baseService';
import axios from 'axios';

const CancelToken = axios.CancelToken;
const sourceArray = new Map();

export default class OffreEmploiService extends BaseService {

    like(offreEmploiId) {
        return this.axiosApiInstance.post(window.API_URL + '/api/OffreEmploi/like/' + offreEmploiId)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    unlike(offreEmploiId) {
        return this.axiosApiInstance.post(window.API_URL + '/api/OffreEmploi/unlike/' + offreEmploiId)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getMatchingOffersOnFormation(lazyParams) {

        if (sourceArray.has("RQ_MatchProfile")) {
            sourceArray.get("RQ_MatchProfile").cancel("Requette annulée");
            sourceArray.delete("RQ_MatchProfile");
        }

        if (sourceArray.has("RQ_MatchFavorite")) {
            sourceArray.get("RQ_MatchFavorite").cancel("Requette annulée");
            sourceArray.delete("RQ_MatchFavorite");
        }

        var source = CancelToken.source();
        sourceArray.set("RQ_MatchFormation", source);

        const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))

        return this.axiosApiInstance.get(window.API_URL + `/api/OffreEmploi/OffreEmploisMatchFormation?` + queryParams, { cancelToken: source.token })
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getMatchingOffersOnFavorite(lazyParams) {
        if (sourceArray.has("RQ_MatchProfile")) {
            sourceArray.get("RQ_MatchProfile").cancel("Requette annulée");
            sourceArray.delete("RQ_MatchProfile");
        }

        if (sourceArray.has("RQ_MatchFormation")) {
            sourceArray.get("RQ_MatchFormation").cancel("Requette annulée");
            sourceArray.delete("RQ_MatchFormation");
        }

        var source = CancelToken.source();
        sourceArray.set("RQ_MatchFavorite", source);

        const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))
        return this.axiosApiInstance.get(window.API_URL + `/api/OffreEmploi/OffreEmploisMatchFavorite?` + queryParams, { cancelToken: source.token })

            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getMatchingOffersOnProfile(lazyParams) {

        if (sourceArray.has("RQ_MatchFavorite")) {
            sourceArray.get("RQ_MatchFavorite").cancel("Requette annulée");
            sourceArray.delete("RQ_MatchFavorite");
        }

        if (sourceArray.has("RQ_MatchFormation")) {
            sourceArray.get("RQ_MatchFormation").cancel("Requette annulée");
            sourceArray.delete("RQ_MatchFormation");
        }

        var source = CancelToken.source();
        sourceArray.set("RQ_MatchProfile", source);

        const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))

        return this.axiosApiInstance.get(window.API_URL + `/api/OffreEmploi/OffreEmploisMatchProfile?` + queryParams, { cancelToken: source.token })

            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getOffreEmploiExtended(offreEmploiId) {
        return this.axiosApiInstance.get(window.API_URL + '/api/OffreEmploi/GetOffreEmploiExtended/' + offreEmploiId)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getOffreEmploiMatchingFormationLastFifty() {
        return this.axiosApiInstance.get(window.API_URL + '/api/OffreEmploi/OffreEmploisMatchFormationLastFifty')
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getAllTempsTravailHebdo() {
        return this.axiosApiInstance.get(window.API_URL + '/api/OffreEmploi/GetAllTempsTravailHebdo')
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getAllTeletravail() {
        return this.axiosApiInstance.get(window.API_URL + '/api/OffreEmploi/GetAllTeletravail')
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    createOrUpdateOffreEmploi(offreEmploi) {
        const jsonOffreEmploi = JSON.stringify(offreEmploi, null, 2);
        return this.axiosApiInstance.post(window.API_URL + '/api/OffreEmploi/CreateOrUpdateOffreEmploi', jsonOffreEmploi)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getOffreEmploisFromRecrutement(recrutementId) {
        return this.axiosApiInstance.get(window.API_URL + '/api/OffreEmploi/getOffreEmploisFromRecrutement/' + recrutementId)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getOffreEmploiEditable(offreEmploiId) {
        return this.axiosApiInstance.get(window.API_URL + '/api/OffreEmploi/GetOffreEmploiEditable/' + offreEmploiId)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    postulerOffreEmploiInterne(offreEmploiId) {
        return this.axiosApiInstance.post(window.API_URL + '/api/OffreEmploi/PostulerOffreEmploiInterne/' + offreEmploiId)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    annulerCandidatureOffreEmploiInterne(offreEmploiId) {
        return this.axiosApiInstance.post(window.API_URL + '/api/OffreEmploi/AnnulerCandidatureOffreEmploiInterne/' + offreEmploiId)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getOffreEmploisCandidatures(lazyParams) {
        const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))
        return this.axiosApiInstance.get(window.API_URL + `/api/OffreEmploi/OffreEmploisCandidatures?` + queryParams)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getListOffreEmploiByFormationId(lazyParams, formationId, contratForFormation) {
        var source = CancelToken.source();
        const queryParams = super.getQueryParams(super.getAPIPagedQuery(lazyParams))
        return this.axiosApiInstance.get(window.API_URL + `/api/OffreEmploi/GetListOffreEmploiByFormationId?` + queryParams + "&formationId=" + formationId + "&contratForFormation=" + contratForFormation, { cancelToken: source.token })
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getOffreEmploiPaginated(lazyParams,entrepriseId) {
        const data = super.getAPIPagedQuery(lazyParams)
        const options = {
            headers: { 'Content-Type': 'application/json' },
            params: {
                entrepriseId : entrepriseId
            } 
        };
        return this.axiosApiInstance.post(window.API_URL + '/api/OffreEmploi/GetOffreEmploiPaginated', JSON.stringify(data), options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    toggleDiffusion(offreEmploiId) {
        return this.axiosApiInstance.post(window.API_URL + '/api/OffreEmploi/ToggleDiffusion/' + offreEmploiId)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    deleteOffreEmploi(offreEmploiId) {
        return this.axiosApiInstance.delete(window.API_URL + '/api/OffreEmploi/DeleteOffreEmploi/' + offreEmploiId)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    deleteOffreEmploiEtudiant(offreEmploiId,etudiantId) {
        return this.axiosApiInstance.delete(window.API_URL + '/api/OffreEmploi/DeleteOffreEmploiEtudiant/' + offreEmploiId + '/' + etudiantId)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getOffreEmploiEntepriseVignette(id) {
        const options = {
            headers: { 'Content-Type': 'application/json' },
            params: { offreEmploiId: id || null }
        };
        return this.axiosApiInstance.get(window.API_URL + '/api/OffreEmploi/GetOffreEmploiEntepriseVignette', options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getOffreEmploiEtudiantsOfOffreEmploi(id, lazyParams, entrepriseId) {
        const data = super.getAPIPagedQuery(lazyParams)
        const options = {
            headers: { 'Content-Type': 'application/json' },
            params: { offreEmploiId: id || null, entrepriseId: entrepriseId }
        };
        return this.axiosApiInstance.post(window.API_URL + '/api/OffreEmploi/GetOffreEmploiEtudiantsOfOffreEmploi', JSON.stringify(data), options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    updateOffreEmploiEtudiant(offreEmploiEtudiant) {
        const jsonOEE = JSON.stringify(offreEmploiEtudiant, null, 2);
        return this.axiosApiInstance.post(window.API_URL + '/api/OffreEmploi/UpdateOffreEmploiEtudiant', jsonOEE)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }

    getFicheCandidatForEntreprise(offreEmploiId, etudiantId) {
        const options = {
            headers: { 'Content-Type': 'application/json' },
            params: { offreEmploiId: offreEmploiId, etudiantId : etudiantId }
        };
        return this.axiosApiInstance.get(window.API_URL + '/api/OffreEmploi/GetFicheCandidatForEntreprise',options)
            .then(res => res.data)
            .catch(err => super.handleHttpError(err));
    }
}