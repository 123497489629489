import { InputText } from "primereact/inputtext"
import { InputNumber } from "primereact/inputnumber"
import { classNames } from "primereact/utils"
import { Trans, useTranslation } from "react-i18next"
import { SelectButton } from "primereact/selectbutton"
import { useState, useRef } from "react"
import "./caracteristiquesPoste.css"
import { Toast } from 'primereact/toast';
import { Wysiwyg } from "../../wysiwyg/wysiwyg"
import { Tooltip } from "primereact/tooltip"
import { AutoComplete } from "primereact/autocomplete"
import RechercheService from "../../../services/rechercheService"
import { Button } from "primereact/button"
import { Fragment } from "react"
import { enumConfWysiwyg } from "../../../enums/enumConfWysiwyg"

export const CaracteristiquesPoste = (props) => {

  const { t } = useTranslation()
  const toast = useRef(null)
  const [filteredValues, setFilteredValues] = useState(null);
  const [selectedValues, setSelectedValues] = useState(null);

  const rechercheService = new RechercheService();

  const privacyOptions = [
    { name: t('offers.posted'), value: "Publique" },
    { name: t('offers.not_posted'), value: "Privee", className: "a-tooltip-private" }
  ]

  const onStatusChange = (e) => {
    props.onInputChange(e, 'statut');
  }
  const searchValues = (event) => {
    let query = event.query;
    rechercheService.getRechercheAppellation(query).then(res => setFilteredValues(res));
  }

  const handleChange = (appellation) => {
    setSelectedValues(appellation);
    if (typeof (appellation) === 'object') {
      setSelectedValues(null);
    }
    if (typeof (appellation) === "object" && appellation !== null) {
      let _appellationId = parseInt(appellation.value);
      let _annonce = { ...props.annonce }
      if (_annonce.offreEmploiAppellations?.length == 0 || _annonce.offreEmploiAppellations?.map(e => e.appellationId).indexOf(_appellationId) == -1) {
        let _appellation = {
          id: _appellationId,
          libelle: appellation.label
        }
        let _oea = {
          appellationId: _appellation.id,
          appellation: _appellation,
          offreEmploiId: _annonce.id
        }
        _annonce.offreEmploiAppellations.push(_oea)
        props.setAnnonce(_annonce);
      }
    }
  }


  const handleRemoveValeur = (appellationId) => {
    let _annonce = { ...props.annonce }
    let _oea = _annonce.offreEmploiAppellations.filter(oea => oea.appellationId != appellationId)
    _annonce.offreEmploiAppellations = _oea
    props.setAnnonce(_annonce);
  }

  const template = (item) => {
    return (
      <span className="ler-item" >
        {item.label}
      </span>
    );
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="p-fluid p-formgrid p-grid">

        <div className="p-field p-col-12 p-sm-12 p-lg-12">
          <div className="dip-flex  fai-title-and-select-container">
            <h2 className="p-mr-2"><Trans i18nKey="general.job" /></h2>
            <div>
              <SelectButton
                value={props.annonce?.statut}
                onChange={(e) => onStatusChange(e)}
                options={privacyOptions}
                optionLabel="name"
                unselectable={false}
                required
                className={`fai-selectbutton ${classNames({ 'p-invalid': props.submitted && !props.annonce?.active })}`}
              />
              <Tooltip target={".a-tooltip-private"} position={'bottom'}>
                <Trans i18nKey='advert.privacy_tooltip' />
              </Tooltip>
            </div>
            <i className="fai-infotext">Diffusée : Créée et visible sur le portail des offres</i>
          </div>
        </div>
        <div className="p-field p-col-12 p-sm-12 p-lg-8">
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-sm-12 p-lg-6">
              <label htmlFor='intitule'><Trans i18nKey='advert.title' /></label><label>*</label>
              <InputText id='intitule'
                value={props.annonce?.intitule}
                onChange={(e) => props.onInputChange(e, 'intitule')}
                required
                maxLength="100"
                className={classNames({ 'p-invalid': props.submitted && !props.annonce?.intitule })} />
              {props.submitted && !props.annonce?.intitule && <small className="p-error"><Trans i18nKey="general.required" />.</small>}
            </div>
            <div className="p-field p-col-12 p-sm-12 p-lg-6">
              <label htmlFor='salaire'><Trans i18nKey='advert.annual_salary' /></label>
              <InputNumber id='salaire'
                value={props.annonce?.salaire?.montantEuro}
                onChange={(e) => props.onInputChange(e, 'salaire')}
                mode="currency"
                currency="EUR"
                locale="fr-FR"
                minFractionDigits={2}
              />
            </div>
            <div className="p-field p-col-12 p-sm-12 p-lg-12">
              <label htmlFor='description'><Trans i18nKey='advert.job_presentation' /></label>
              <Wysiwyg
                value={props.annonce?.description}
                onChange={props.onEditorChange}
                Conf={enumConfWysiwyg.BASIC}
                defaultHeight={300}
              />
            </div>
          </div>
        </div>

        <div className="p-field p-col-12 p-sm-12 p-lg-4">
          <label htmlFor='metier'><Trans i18nKey='appellation.metier' /></label>
          <label>*</label>
          <label>{props.submitted && !props.annonce?.offreEmploiAppellations?.length > 0 && <small className="p-error"><Trans i18nKey="general.required" />.</small>}</label>
          <div>
            <AutoComplete
              panelClassName="ler-autcomplete-panel"
              minLength="3"
              value={selectedValues}
              suggestions={filteredValues}
              completeMethod={searchValues}
              placeholder="Choisissez un métier"
              delay="500"
              itemTemplate={template}
              onChange={(e) => handleChange(e.value)}
              className={classNames({ 'p-invalid': props.submitted && !props.annonce?.offreEmploiAppellations?.length > 0})}
            />
            {props.annonce?.offreEmploiAppellations?.length > 0 && <div className="p-mt-2 fai-competence-container">
              {props.annonce?.offreEmploiAppellations?.sort((a, b) => (a.appellation?.libelle > b.appellation?.libelle) ? 1 : -1).map((oea, index) => (
                <Fragment key={`oea~${index}`}>
                  <div className='fai-item'>
                    <span>{oea.appellation?.libelle}</span>
                    <div>
                      <Button icon="pi pi-times-circle" className="p-button-rounded p-button-danger p-button-text p-mr-2" onClick={(e) => handleRemoveValeur(oea.appellationId)} />
                    </div>
                  </div>
                </Fragment>
              ))}
            </div>}
          </div>
        </div>
      </div>
    </>
  )
}