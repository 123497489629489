import React, { useEffect, useState, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import UserService from '../../services/userService';
import './formRegisterStudent.css';
import './formRegisterCompany.css';
import { Link } from 'react-router-dom';
import { enumRoles } from '../../enums/enumRoles';

import { loginUser } from '../../context/actions';
import { useAuthDispatch } from '../../context/context';

export const FormRegister = (props) => {
    const [formUser, setFormUser] = useState({});
    const [submitting, setSubmitting] = useState(false)
    const { t } = useTranslation();
    const history = useHistory();
    const toast = useRef(null);
    const userService = new UserService();

    const [initialValues, setInitialValues] = useState({ 
        nom: '', 
        prenom: '', 
        nomEntreprise: '', 
        email: '', 
        password: '', 
        password2: '', 
        phoneNumber: '', 
        accept: false 
    });

    const dispatch = useAuthDispatch();

    const [cssType, setCssType] = useState('frs-etudiant');
    useEffect(() => {
        if (props.userType == enumRoles.ETUDIANT) {
            setCssType('frs-etudiant');
        }
        else if (props.userType == enumRoles.ENTREPRISE) {
            setCssType('frs-entreprise');
        }

    }, [props.userType]);

    useEffect(() => {
        if (props.preSetFormRegister) {
            setInitialValues({
                nom: props.preSetFormRegister?.nom ? props.preSetFormRegister.nom : '',
                prenom: props.preSetFormRegister?.prenom ? props.preSetFormRegister.prenom : '',
                nomEntreprise: props.preSetFormRegister?.nomEntreprise ? props.preSetFormRegister.nomEntreprise : '',
                email: props.preSetFormRegister?.email ? props.preSetFormRegister.email : '',
                password: '',
                password2: '',
                phoneNumber: props.preSetFormRegister?.telephone ? props.preSetFormRegister.telephone : '',
                accept: false
            });
        }
    }, [props.preSetFormRegister]);

    const validate = (user) => {
        let errors = {};

        if (!user.nom) {
            errors.nom = <Trans i18nKey="general.required" />;
        }

        if (!user.prenom) {
            errors.prenom = <Trans i18nKey="general.required" />;
        }

        if (props.userType == enumRoles.ENTREPRISE) {
            if (!user.nomEntreprise) {
                errors.nomEntreprise = <Trans i18nKey="general.required" />;
            }
        }

        if (!user.email) {
            errors.email = <Trans i18nKey="general.required" />;
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i.test(user.email)) {
            errors.email = <Trans i18nKey="general.invalid_email" />;
        }

        if (!user.password) {
            errors.password = <Trans i18nKey="general.required" />;
        }
        else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/.test(user.password)) {
            errors.password = <Trans i18nKey="general.invalid_password" />;
        }

        if (props.userType == enumRoles.ENTREPRISE && !user.phoneNumber) {
            errors.phoneNumber = <Trans i18nKey="general.required" />;
        }
        else if (!/^[0-9]{0,13}$/.test(user.phoneNumber) && user.phoneNumber) {
            errors.phoneNumber = <Trans i18nKey="general.invalid_phone_number" />;
        }

        if (!user.accept) {
            errors.accept = <Trans i18nKey="general.need_accept_terms" />;
        }

        return errors;
    };

    const onSubmit = (user, form) => {
        setSubmitting(true)
        setFormUser(user);
        userService.existEmail(user.email).then(reponse => {
            let bEmailExist = reponse;
            if (bEmailExist) {
                toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('register.account_notcreated_emailexists'), life: 3000 });
                setSubmitting(false)
            }
            else {
                try {
                    creerCompte(user);
                    form.restart();
                    toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('register.account_created'), life: 3000 });
                } catch (error) {
                    toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_save'), life: 3000 });
                    setSubmitting(false)
                }

            }
        })
    };

    const creerCompte = (user) => {
        let payload = { userEmail: user.email, password: user.password };
        switch (props.userType) {
            case enumRoles.ETUDIANT:
                userService.createEtudiant(user, history.location.state?.previousUrl).then(_user => {
                    loginUser(dispatch, payload).then(() => {
                        setSubmitting(false);
                        if (_user.isRegistrationOnboardingCompleted == false)
                            props.showOnboarding();
                    });
                })
                break;
            case enumRoles.ENTREPRISE:
                userService.createEntreprise(user).then(_user => {
                    loginUser(dispatch, payload).then(() => {
                        // history.push('/');
                        history.push({ pathname: '/', state: "firstConnection" });
                    });
                })
                break;
            default:
                throw Error;
                break;
        }
    }

    const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
    const getFormErrorMessage = (meta) => {
        return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
    };

    const passwordHeader = <h5><Trans i18nKey="general.pick_password" /></h5>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="p-mt-2"><Trans i18nKey="general.suggestions" /></p>
            <ul className="p-pl-2 p-ml-2 p-mt-0" style={{ lineHeight: '1.5' }}>
                <li><Trans i18nKey="general.least_lowercase" /></li>
                <li><Trans i18nKey="general.least_uppercase" /></li>
                <li><Trans i18nKey="general.least_numeric" /></li>
                <li><Trans i18nKey="general.minimum_caracters" /></li>
            </ul>
        </React.Fragment>
    );

    return (
        <>
            <Toast ref={toast} />
            {props.homeDesign ? (
                <div>
                    <div className={`card frs-card ${submitting && "dip-disabled"}`}>
                        <Form onSubmit={onSubmit} initialValues={{ nom: '', prenom: '', nomEntreprise: '', email: '', password: '', password2: '', phoneNumber: '', accept: false }} validate={validate} render={({ handleSubmit }) => (
                            <form onSubmit={handleSubmit} className={`p-fluid fr-form ${cssType}`}>
                                <div className={`p-grid fr-form ${cssType}`}>
                                    <Field name="nom" render={({ input, meta }) => (
                                        <div className="p-field p-mb-0 p-col-12 p-sm-12 p-lg-6">
                                            <label htmlFor="nom" className={classNames({ 'p-error': isFormFieldValid(meta) })}><Trans i18nKey="general.lastname" /></label><label>*</label>
                                            <InputText
                                                {...input}
                                                id="nom"
                                                maxLength="80"
                                                // placeholder="Ex : Dupond"
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>
                                    )} />
                                    <Field name="prenom" render={({ input, meta }) => (
                                        <div className="p-field p-mb-0 p-col-12 p-sm-12 p-lg-6">
                                            <label htmlFor="prenom" className={classNames({ 'p-error': isFormFieldValid(meta) })}><Trans i18nKey='general.firstname' /></label><label>*</label>
                                            <InputText
                                                {...input}
                                                id="prenom"
                                                maxLength="80"
                                                // placeholder="Ex : Jean"
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>
                                    )} />
                                    {props.userType === enumRoles.ENTREPRISE &&
                                        <Field name="nomEntreprise" render={({ input, meta }) => (
                                            <div className="p-field p-col-12">
                                                <label htmlFor="nomEntreprise" className={classNames({ 'p-error': isFormFieldValid(meta) })}><Trans i18nKey="general.lastname_company" /></label><label>*</label>
                                                <InputText
                                                    {...input}
                                                    id="nomEntreprise"
                                                    maxLength="80"
                                                    // placeholder="Ex : Dupond_Entreprise"
                                                    className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                                />
                                                {getFormErrorMessage(meta)}
                                            </div>
                                        )} />
                                    }
                                    <Field name="email" render={({ input, meta }) => (
                                        <div className="p-field p-col-12">
                                            <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                                                <Trans i18nKey="general.emailAddress" /> {props.userType === enumRoles.ETUDIANT && <Trans i18nKey="general.personnal" /> || props.userType === enumRoles.ENTREPRISE && <Trans i18nKey="general.professional" />}</label><label>*</label>
                                            <span className="p-input-icon-right">
                                                <i className="pi pi-envelope" />
                                                <InputText
                                                    {...input}
                                                    id="email"
                                                    maxLength="255"
                                                    // placeholder="Ex : jeandupont@mail.fr"
                                                    className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                                />
                                            </span>
                                            {getFormErrorMessage(meta)}
                                        </div>
                                    )} />
                                    <Field name="password" render={({ input, meta }) => (
                                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                                            <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid(meta) })}><Trans i18nKey="general.pick_password" /></label><label>*</label>
                                            <Password
                                                {...input}
                                                toggleMask
                                                id="password"
                                                header={passwordHeader} footer={passwordFooter}
                                                promptLabel={t('general.enter_password')} weakLabel={t('general.weak')}
                                                mediumLabel={t('general.medium')} strongLabel={t('general.strong')}
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>
                                    )} />
                                    <Field name="phoneNumber" render={({ input, meta }) => (
                                        <div className="p-field p-col-12 p-sm-12 p-lg-6">
                                            <label htmlFor="phoneNumber" className={classNames({ 'p-error': isFormFieldValid(meta) })}><Trans i18nKey="general.phone_number" /></label><label>*</label>
                                            <span className="p-input-icon-right">
                                                <i className="pi pi-phone" />
                                                <InputText
                                                    {...input}
                                                    id="phoneNumber"
                                                    maxLength="13"
                                                    // placeholder="Ex : 0123456789"
                                                    className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                                />
                                            </span>
                                            {getFormErrorMessage(meta)}
                                        </div>
                                    )} />
                                </div>
                                <div className='fr-required-fields p-mr-3'>
                                    <span>*</span><Trans i18nKey="general.required_fields" />
                                </div>
                                <Field name="accept" type="checkbox" render={({ input, meta }) => (
                                    <div className="p-field-checkbox field-margin dip-jc-center fr-general-condition">
                                        <Checkbox
                                            {...input}
                                            inputId="accept"
                                            className={classNames({ 'p-invalid': isFormFieldValid(meta), 'p-mr-2': true })}
                                        />
                                        <Link to="/pdf/CGU_DiplomAdvisor.pdf" target="_blank" className={classNames({ 'p-error': isFormFieldValid(meta), 'general-conditions': true })} download><Trans i18nKey="general.agree_terms" /><span>*</span></Link>
                                    </div>
                                )} />
                                <div className="frs-submit">
                                    <Button id={cssType == 'frs-entreprise' ? 'create-company-account' : 'create-student-account'} loading={submitting} type="submit" className={`p-mt-2 frs-button ${cssType}`} >
                                        {props.userType === enumRoles.ETUDIANT && t('register.student') || props.userType === enumRoles.ENTREPRISE && t('register.company')}
                                    </Button>
                                </div>
                            </form>
                        )} />
                    </div>
                </div>
            ) : (
                <div className={`${props.className} p-d-flex frs-style dip-w-100 dip-jc-end`}>
                    <Fieldset>
                        <div className={`card frs-card ${submitting && "dip-disabled"}`}>
                            {props.hasTitle && <div className="title"><Trans i18nKey="general.subscription" /></div>}
                            <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate} render={({ handleSubmit }) => (
                                <form onSubmit={handleSubmit} className={`p-fluid fr-form ${cssType}`}>
                                    <Field name="prenom" render={({ input, meta }) => (
                                        <div className="p-field field-margin">
                                            <label htmlFor="prenom" className={classNames({ 'p-error': isFormFieldValid(meta) })}><Trans i18nKey='general.firstname' /></label><label>*</label>
                                            <InputText
                                                {...input}
                                                autoFocus
                                                id="prenom"
                                                maxLength="80"
                                                // placeholder="Ex : Jean"
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>
                                    )} />
                                    <Field name="nom" render={({ input, meta }) => (
                                        <div className="p-field field-margin">
                                            <label htmlFor="nom" className={classNames({ 'p-error': isFormFieldValid(meta) })}><Trans i18nKey="general.lastname" /></label><label>*</label>
                                            <InputText
                                                {...input}
                                                id="nom"
                                                maxLength="80"
                                                // placeholder="Ex : Dupond"
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>
                                    )} />
                                    {props.userType === enumRoles.ENTREPRISE &&
                                        <Field name="nomEntreprise" render={({ input, meta }) => (
                                            <div className="p-field field-margin">
                                                <label htmlFor="nomEntreprise" className={classNames({ 'p-error': isFormFieldValid(meta) })}><Trans i18nKey="general.lastname_company" /></label><label>*</label>
                                                <InputText
                                                    {...input}
                                                    id="nomEntreprise"
                                                    maxLength="80"
                                                    className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                                    disabled={props.preSetFormRegister ? true : false}
                                                />
                                                {getFormErrorMessage(meta)}
                                            </div>
                                        )} />
                                    }
                                    <Field name="email" render={({ input, meta }) => (
                                        <div className="p-field field-margin">
                                            <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid(meta) })}>
                                                <Trans i18nKey="general.emailAddress" /> {props.userType === enumRoles.ETUDIANT && <Trans i18nKey="general.personnal" /> || props.userType === enumRoles.ENTREPRISE && <Trans i18nKey="general.professional" />}</label><label>*</label>
                                            <span className="p-input-icon-right">
                                                <i className="pi pi-envelope" />
                                                <InputText
                                                    {...input}
                                                    id="email"
                                                    maxLength="255"
                                                    className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                                    disabled={props.preSetFormRegister ? true : false}
                                                />
                                            </span>
                                            {getFormErrorMessage(meta)}
                                        </div>
                                    )} />
                                    <Field name="password" render={({ input, meta }) => (
                                        <div className="p-field field-margin">
                                            <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid(meta) })}><Trans i18nKey="general.pick_password" /></label><label>*</label>
                                            <Password
                                                {...input}
                                                toggleMask
                                                id="password"
                                                header={passwordHeader} footer={passwordFooter}
                                                promptLabel={t('general.enter_password')} weakLabel={t('general.weak')}
                                                mediumLabel={t('general.medium')} strongLabel={t('general.strong')}
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                            />
                                            {getFormErrorMessage(meta)}
                                        </div>
                                    )} />
                                    <Field name="phoneNumber" render={({ input, meta }) => (
                                        <div className="p-field field-margin">
                                            <label htmlFor="phoneNumber" className={classNames({ 'p-error': isFormFieldValid(meta) })}><Trans i18nKey="general.phone_number" /></label><label>{props.userType === enumRoles.ENTREPRISE && '*'}</label>
                                            <span className="p-input-icon-right">
                                                <i className="pi pi-phone" />
                                                <InputText
                                                    {...input}
                                                    id="phoneNumber"
                                                    maxLength="13"
                                                    // placeholder="Ex : 0123456789"
                                                    className={classNames({ 'p-invalid': isFormFieldValid(meta) })}
                                                />
                                            </span>
                                            {getFormErrorMessage(meta)}
                                        </div>
                                    )} />
                                    <Field name="accept" type="checkbox" render={({ input, meta }) => (
                                        <div className="p-field-checkbox field-margin">
                                            <Checkbox
                                                {...input}
                                                inputId="accept"
                                                className={classNames({ 'p-invalid': isFormFieldValid(meta), 'p-mr-2': true })}
                                            />
                                            <Link to="/pdf/CGU_DiplomAdvisor.pdf" target="_blank" className={classNames({ 'p-error': isFormFieldValid(meta), 'general-conditions': true })} download><Trans i18nKey="general.agree_terms" /><span>*</span></Link>
                                        </div>
                                    )} />
                                    <div className="frs-submit">
                                        <Button id={cssType == 'frs-entreprise' ? 'create-company-account' : 'create-student-account'} loading={submitting} type="submit" className={`p-mt-2 frs-button ${cssType}`} >
                                            {props.userType === enumRoles.ETUDIANT && t('register.student') || props.userType === enumRoles.ENTREPRISE && t('register.company')}
                                        </Button>
                                    </div>
                                </form>
                            )} />
                        </div>
                    </Fieldset>
                </div>
            )}
        </>
    );
}
