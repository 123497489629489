import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Fragment, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { enumContextFormationThumbnail } from "../../enums/enumContextFormationThumbnail";
import './formationsFavorites.css'
import { NewFormationThumbnail } from "../../components/thumbnail/newFormationThumbnail";
import { MenuSearchFilter } from "../../scenes/rechercheAffinee/menu/menuSearchFilter";
import RechercheContext from "../../scenes/rechercheAffinee/RechercheContext";
import { enumCategorieRecherche } from "../../enums/enumCategorieRecherche";
import { ThumbnailPaginator } from "../../components/paginator/thumbnailPaginator";
import RechercheService from "../../services/rechercheService";
import { MenuPopUpSearchFilter } from "../../scenes/rechercheAffinee/menu/menuPopUpSearchFilter";
import EntrepriseService from "../../services/entrepriseService";
import RoleHelper from "../../utils/roleHelper";
import { useAuthState } from "../../context/context";

export const FormationsFavorites = (props) => {

    const history = useHistory();
    const currentUser = useAuthState();
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const toast = useRef(null);
    const [formationsFavorites, setFormationsFavorites] = useState([]);
    const [filtersActiveIndex, setFiltersActiveIndex] = useState([]);
    const [tokenResetFilters, setTokenResetFilters] = useState(null);

    const _lazyParamsDefault = {
        first: 0,
        rows: 12,
        page: 1,
        sortField: 'dateMiseEnFavoris',
        sortOrder: 1,
        filters: history.location?.state?.filters || []
    };

    const [lazyParams, setLazyParams] = useState(_lazyParamsDefault);

    const rechercheService = new RechercheService();
    const entrepriseService = new EntrepriseService();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;

    useEffect(() => {
        loadLazyData(lazyParams);
    }, [lazyParams]);

    const loadLazyData = (_lazyParams) => {
        setLoading(true);
        if(RoleHelper.isEntreprise(currentUser) || RoleHelper.isAdminImpersonatingEntreprise(currentUser, history.location.pathname)) {
            entrepriseService.getEntreprisesOfRecruteur(adminImpersonatingRecruteurId).then((id) => {
                rechercheService.getFormationFavoritesEntreprise(_lazyParams, id).then(_pagedFavorites => {
                    setTotalCount(_pagedFavorites.totalCount)
                    setFormationsFavorites(_pagedFavorites.items);
                    setLoading(false);
                })
                    .catch(() => toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load'), life: 3000 }))
                    .finally(() => setLoading(false));
            })
        }
        else {
            rechercheService.getFormationFavorites(_lazyParams).then(_pagedFavorites => {
                setTotalCount(_pagedFavorites.totalCount)
                setFormationsFavorites(_pagedFavorites.items);
                setLoading(false);
            })
                .catch(() => toast.current.show({ severity: 'error', summary: t('general.failed'), detail: t('errors.cant_load'), life: 3000 }))
                .finally(() => setLoading(false));
        }
    }


    const contextRecherche = {
        lazyParams,
        setLazyParams,
        rechercheApi: loadLazyData,
        totalFormationsRecords: totalCount,
        categorieRecherche: enumCategorieRecherche,
        tokenResetFilters
    }

    const onClickChangePage = (lazyParams) => {
        setLazyParams(lazyParams);
    }

    const onLinkClick = () => {
        history.push('/rechercheAffinee');
    }

    const onUnlike = () => {
        loadLazyData(lazyParams)
    }

    const handleClicEffacerFiltres = (e) => {
        e.preventDefault()
        try {
            setTokenResetFilters(new Date())
            contextRecherche.setLazyParams(_lazyParamsDefault)
            contextRecherche.rechercheApi(_lazyParamsDefault)
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div className={props.className}>
            <Toast ref={toast} />
            <RechercheContext.Provider value={contextRecherche}>
                <h2 id="top"><Trans i18nKey='favorite.myFavorites.full' /></h2>
                <div className="refined-search-lower-container">
                    <MenuSearchFilter
                        className={`refined-search-filters-container`}
                        activeIndex={filtersActiveIndex}
                        setActiveIndex={setFiltersActiveIndex}
                        handleClicEffacerFiltres={handleClicEffacerFiltres}
                        isInFavoris={true}
                    />
                    <div className={`refined-search-right-container`}>
                        {loading ?
                            <div className="center-element-oc"><ProgressSpinner /></div>
                            :
                            <>
                                <div className="p-grid">
                                    {formationsFavorites.map((ff, indexFF) => (
                                        <Fragment key={`${ff.id}~${indexFF}`}>
                                            <div className="p-col-12 p-sm-12 p-lg-6">
                                                <NewFormationThumbnail formation={ff} small={true} onUnlike={e => onUnlike()} context={enumContextFormationThumbnail.FAVORI} loadLazyData={e => loadLazyData(e)} lazyParams={lazyParams} />
                                            </div>
                                        </Fragment>
                                    ))}
                                </div>
                                {formationsFavorites?.length > 0 && <div className="p-mt-4 dip-ta-center">
                                    <ThumbnailPaginator
                                        lazyParams={lazyParams} setLazyParams={setLazyParams} totalRecords={totalCount}
                                        backgroundColor={props.backgroundColor} onClickChangePage={onClickChangePage}
                                    />
                                </div>}
                            </>
                        }
                        {!loading && formationsFavorites.length == 0 && <div className="center-element-oc grey-italic-message">
                            <span><Trans i18nKey="favorite.no_favorites" /></span>
                            <span className="link-to-rechercheAffinee" onClick={e => onLinkClick()} ><Trans i18nKey="general.clicking_here" /></span>
                            <span>.</span>
                        </div>}
                    </div>
                </div>
                {/* <div className='p-jc-center p-mt-2 refined-search-float-button'>
                    <MenuPopUpSearchFilter activeIndex={filtersActiveIndex} setActiveIndex={setFiltersActiveIndex} handleClicEffacerFiltres={handleClicEffacerFiltres} isInFavoris={true} />
                </div> */}
            </RechercheContext.Provider>

        </div>
    )
}