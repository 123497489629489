import { ProgressSpinner } from "primereact/progressspinner";
import React, { Fragment, useContext, useEffect } from "react"
import RechercheContext from './RechercheContext';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { enumContextFormationThumbnail } from "../../enums/enumContextFormationThumbnail";
import { NewFormationThumbnail } from "../../components/thumbnail/newFormationThumbnail";
import { enumTypeRechercheAffinee } from "../../enums/enumTypeRechercheAffinee";
import { EtudiantThumbnail } from "../../components/thumbnail/etudiantThumbnail";
import { ThumbnailPaginator } from "../../components/paginator/thumbnailPaginator";


export const RechercheAffineeResultats = (props) => {

    const { t } = useTranslation()
    const history = useHistory();
    const contextRecherche = useContext(RechercheContext);
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId

    return (
        <div className={props.className}>
            {props.loading ? (
                <div className="p-col-12" style={{ textAlign: 'center' }}>
                    <ProgressSpinner></ProgressSpinner>
                </div>
            ) : (
                (props.formations?.length > 0 && contextRecherche.typeRecherche == enumTypeRechercheAffinee.FORMATION) || props.candidats?.length > 0 && (contextRecherche.typeRecherche == enumTypeRechercheAffinee.CANDIDAT) ? (
                    props.loading === true ?
                        (<div style={{ marginTop: "15%", textAlign: 'center' }}><ProgressSpinner></ProgressSpinner></div>)
                        :
                        (<>{contextRecherche.typeRecherche == enumTypeRechercheAffinee.FORMATION && <div className={"container-for-horizontal-display"}>{props.formations?.map((formation, indexFormation) => (
                            <Fragment key={`${formation.id}~${indexFormation}`}>
                                <NewFormationThumbnail
                                    formation={formation}
                                    className={"column-search-item"}
                                    context={enumContextFormationThumbnail.RECHERCHE}
                                    adminImpersonatingRecruteurId={adminImpersonatingRecruteurId}
                                />
                            </Fragment>
                        ))}</div>}
                            {contextRecherche.typeRecherche == enumTypeRechercheAffinee.CANDIDAT && <div className={"container-for-horizontal-display"}>{props.candidats?.map((candidat, indexCandidat) => (
                                <Fragment key={`${candidat.id}~${indexCandidat}`}>
                                    <EtudiantThumbnail
                                        etudiant={candidat}
                                        small={!(props.mainFilterActiveIndex == 0)}
                                        className={"column-search-item"}
                                        isInAList={true}
                                        hasFormationAccordion={true}
                                        hasCheckableProfile={true}
                                        toast={props.toast} />
                                </Fragment>
                                // <div>
                                //     TEST
                                // </div>
                            ))}</div>}
                        </>)
                ) : t('search.no_results_matching')
            )}
            {(props.formations?.length > 0 || props.candidats?.length > 0) && !props.loading &&
                <div
                    className="p-mt-4 dip-ta-center">
                    <ThumbnailPaginator
                        lazyParams={props.lazyParams} setLazyParams={props.setLazyParams} totalRecords={props.totalFormationsRecords}
                        backgroundColor={props.backgroundColor} onClickChangePage={props.onClickChangePage}
                    />
                </div>
            }
        </div>
    )
}