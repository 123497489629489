import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { DipTag } from "../tag/dipTag";
import "./candidateThumbnail.css"
import { DisplayProfileImage } from "../image/displayProfileImage";
import { Avatar } from "primereact/avatar";
import { LikeEtudiant } from "../like/likeEtudiant";
import { ProjetOverlayPanel } from "../overlayPanel/projetOverlayPanel";
import RoleHelper from "../../utils/roleHelper";
import { useAuthState } from "../../context/context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const CandidateThumbnail = (props) => {

    const history = useHistory();
    const adminImpersonatingRecruteurId = history.location?.state?.adminImpersonatingRecruteurId;
    const { t } = useTranslation();
    const currentUser = useAuthState()

    const openVueCandidat = () => {
        const pathname = `/Candidat/${props.etudiant.urlCode}/${props.etudiant.prenom}-${props.etudiant.nom}`;
        RoleHelper.windowOpenadminImpersonatingRecruteurIdStorage(pathname, adminImpersonatingRecruteurId);
    }

    return (
        <div className="ct-oe-item-container">
            <div className="ct-profile-image">
                {props.etudiant.fichierPhoto ? (
                    <DisplayProfileImage imageId={props.etudiant.fichierPhoto?.id} alt={t('meta-img.alt-student-picture') + " " + props.etudiant?.prenom + " " + props.etudiant?.nom} />
                ) : (
                    <Avatar icon="pi pi-user" />
                )
                }
                {/* <span>S</span> */}
                <div className="ct-like-container">
                    <div className="dip-flex-row">
                        {RoleHelper.isEntreprise(currentUser) || adminImpersonatingRecruteurId && <div className="p-mr-2"><ProjetOverlayPanel etudiantId={props.etudiant.id} /></div>}
                        <LikeEtudiant etudiant={props.etudiant} toast={props.toast} />
                    </div>
                </div>
            </div>
            <div className="p-shadow-4 ct-box-container" >
                <div>
                    <div className="ct-titre p-mb-2">{props.etudiant.prenom} {props.etudiant.nom}</div>
                    <div className="ct-contrats">
                        {(props.etudiant.paireContrats?.some(pc => pc.code == "STA")) &&
                            <DipTag
                                className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                hasLetterSpacing
                                label={t('offers.traineeship')}
                            />}
                        {(props.etudiant.paireContrats?.some(pc => pc.code == "ALTCA")) &&
                            <DipTag
                                className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                hasLetterSpacing
                                label={t('formation.app_contract')}
                            />}
                        {(props.etudiant.paireContrats?.some(pc => pc.code == "ALTCP")) &&
                            <DipTag
                                className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                hasLetterSpacing
                                label={t('formation.pro_contract')}
                            />}
                        {(props.etudiant.paireContrats?.some(pc => pc.code == "CDD")) &&
                            <DipTag
                                className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                hasLetterSpacing
                                label={t('enums.paireContrat.CDD')}
                            />}
                        {(props.etudiant.paireContrats?.some(pc => pc.code == "CDI")) &&
                            <DipTag
                                className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                hasLetterSpacing
                                label={t('enums.paireContrat.CDI')}
                            />}
                        {(props.etudiant.paireContrats?.some(pc => pc.code == "CDIINTERIM")) &&
                            <DipTag
                                className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                hasLetterSpacing
                                label={t('CDI Intérimaire')}
                            />}
                        {(props.etudiant.paireContrats?.some(pc => pc.code == "MISINTERIM")) &&
                            <DipTag
                                className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                hasLetterSpacing
                                label={"Mission intérimaire"}
                            />}
                        {(props.etudiant.paireContrats?.some(pc => pc.code == "PROCOM")) &&
                            <DipTag
                                className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                hasLetterSpacing
                                label={"Profession commerciale"}
                            />}
                        {(props.etudiant.paireContrats?.some(pc => pc.code == "PROLIB")) &&
                            <DipTag
                                className={"dip-tag-blue dip-tag-fs-sm dip-fw-600 p-mr-2 p-mb-2"}
                                hasLetterSpacing
                                label={"Profession libérale"}
                            />}
                    </div>
                </div>
                <div>
                    <div className="ct-text">
                        {props.etudiant.rechercheActiveCalendrier && <div className="p-mb-2"><i className="pi pi-calendar p-mr-2" />{t('company.available_until') + ' ' + props.etudiant.rechercheActiveCalendrier}</div>}
                        {(props.etudiant.mobiliteInternationale || props.etudiant.mobiliteFrancaise || props.etudiant.mobiliteRegion || props.etudiant.mobiliteDepartement) && <div className="p-mb-2 ct-mobility">
                            <i className="pi pi-map-marker p-mr-2" />
                            <span>{t('profile.mobility.base')}</span>
                            <div>
                                <div>{props.etudiant.mobiliteInternationale && t('geography.international_except_france')}</div>
                                <div>{props.etudiant.mobiliteFrancaise && t('geography.france')}</div>
                                <div>{props.etudiant.mobiliteRegion.map((r, i) => {
                                    if (i < 3)
                                        return <div key={r.id + i}>{r.libelle}</div>
                                })}</div>
                                <div>{props.etudiant.mobiliteDepartement.map((d, i) => {
                                    if (props.etudiant.mobiliteRegion?.length + i < 3)
                                        return <div key={d.id + i}>{d.libelle}</div>
                                })}</div>
                                {props.etudiant.mobiliteRegion?.length + props.etudiant.mobiliteDepartement?.length > 3 && <div>...</div>}
                            </div>
                        </div>}
                    </div>

                    <div className="dip-flex-row dip-jc-end">
                        <Button className="dip-btn-iris-bg p-button-rounded dip-ai-center " onClick={openVueCandidat}>
                            {"Consulter le profil"}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}